.application-header {
  color: #000;
  text-align: center;
  background-color: #fbf9e7;
  margin: 0;
  padding: 60px 30px;
  font-family: Playfair, cursive;
  font-size: 65pt;
}

.login-bar {
  color: #e3e0c8;
  text-align: right;
  background-color: #2b2926;
  margin: 0;
  padding: 10px 30px;
  font-family: Julius Sans One, sans-serif;
  font-size: 12px;
}

.login-bar .login-button {
  color: #d43229;
  background-color: #000;
  border-color: #000;
}

.login-bar .login-button:hover {
  color: #fbf9e7;
  background-color: #d43229;
  border-color: #d43229;
}

.login-bar .login-button-clicked {
  color: #d43229;
  background-color: #000;
  border-color: #000;
}

.navigation-bar {
  background-color: #d43229;
}

.navigation-bar-buttons {
  min-width: 600px;
  max-width: 1000px;
  margin: 0 auto;
}

.colStyle {
  text-align: center;
  color: #fbf9e7;
  background-color: #d43229;
  padding: 20px;
  font-family: Julius Sans One, sans-serif;
  transition: background-color .3s;
}

.colStyle .navLink {
  color: inherit;
  display: block;
}

.colStyle:hover {
  color: #fbf9e7;
  background-color: #802e28;
}

.colStyle .navLink:active {
  color: #fbf9e7;
}

.colStyle.active {
  color: #fbf9e7;
  background-color: #2b2926;
}

.colStyle.active:hover {
  color: #000;
  background-color: #802e28;
}

.application-footer {
  color: #fbf9e7;
  text-align: center;
  background-color: #2b2926;
  margin: 0;
  padding: 20px;
  font-family: Cormorant Garamond, serif;
}
/*# sourceMappingURL=index.beeac093.css.map */
