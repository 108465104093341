/* NavigationBar.scss */
@use "../colors" as *;

.navigation-bar {
  background-color: $color5;
}

.navigation-bar-buttons {
  margin: 0px auto;
  min-width: 600px;
  max-width: 1000px;
}

.colStyle {
  text-align: center;
  background-color: $color5;
  padding: 20px;
  transition: background-color 0.3s ease;
  color: $color1;
  font-family: "Julius Sans One", sans-serif;
}

.colStyle .navLink {
  color: inherit; /* This makes the link color the same as the parent's color */
  display: block; /* This makes the link take up the full space of the parent */
}

.colStyle:hover {
  background-color: $color4;
  color: $color1; /* This changes the font color to eerie black when the column is hovered over */
}

.colStyle .navLink:active {
  color: $color1; /* This changes the font color to space cadet when the link is clicked */
}

.colStyle.active {
  background-color: $color2; /* This changes the background color to green when the column is clicked */
  color: $color1;
}

.colStyle.active:hover {
  background-color: $color4; /* This changes the background color to color1 when the active column is hovered over */
  color: $color3;
}
