/* NavigationBar.scss */
@use "../colors" as *;

.login-bar {
  font-family: "Julius Sans One", sans-serif;
  color: $color6;
  background-color: $color2;
  text-align: right;
  font-size: 12px;
  margin: 0;
  padding: 10px 30px;

  .login-button {
    background-color: $color3;
    border-color: $color3;
    color: $color5;
  }

  .login-button:hover {
    background-color: $color5;
    border-color: $color5;
    color: $color1;
  }

  .login-button-clicked {
    background-color: $color3;
    border-color: $color3;
    color: $color5;
  }
}
