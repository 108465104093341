/* ApplicationFooter.scss */
@use "../colors" as *;

.application-footer {
  font-family: "Cormorant Garamond", serif;
  color: $color1;
  background-color: $color2;
  padding: 20px;
  margin: 0;
  text-align: center;
}
