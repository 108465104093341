/* ApplicationHeader.scss */
@use "../colors" as *;

.application-header {
  font-family: "Playfair", cursive;
  color: $color3;
  background-color: $color1;
  font-size: 65pt;
  text-align: center;
  margin: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 60px;
  padding-bottom: 60px;
}
